const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request/__request_contentType_json`);

 // 分销火车票退款列表接口文档地址： http://219.128.52.2:9900/doc.html#/trains/order-controller/buyerRefundListUsingPOST
const trains_order_buyerRefundList = (pParameter) => {
  if (!pParameter) pParameter = {};
  const p = {
    method: 'post',
    urlSuffix: '/trains/order/buyerRefundList',
    data: pParameter
  };
  return Q.when()
    .then(function () {
      return __request(p);
    })
    .then(function (data) {
      return Q.resolve(data);
    })
    .catch(function (ex) {
      console.error(ex);
      if (ex) {
        if (ex.stack) {
          console.error(ex.stack);
        }
      }
      return Q.reject(ex);
    })
    ;
};
module.exports = trains_order_buyerRefundList;
