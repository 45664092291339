import Q from "q";
import moment from "moment";
// 退款列表
import trains_order_buyerRefundList from "@/lib/data-service/trains/trains_order_buyerRefundList.js";
// 更改备注
import trains_order_updateRefundRemark from "@/lib/data-service/trains/trains_order_updateRefundRemark.js";

// 城市站点组件
import TrainCitySelector from "yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/train-city-selector/1.1.0/index.vue";

export default {
  components: {
    TrainCitySelector,
  },
  data() {
    return {
      loading: false,
      form: {
        currentPage: 1,
        pageSize: 10,
        orderNo: "",
        passengerName: "",
        trainNo: "",
        orderType: "",
        refundStatus: "",
        fromStationSn: "",
        toStationSn: "",
        beginStartDate: "",
        endStartDate: "",
        beginBookingDate: "",
        endBookingDate: "",
      },
      departDate: null,
      reserveDate: null,
      fromCity: {},
      toCity: {},
      dialogVisible: false,
      remarkForm: {
        refundNo: "",
        remark: "",
      },
      remarRule: {
        remark: [
          { required: true, message: "请输入备注信息", trigger: "blur" },
        ],
      },
      allocation: {
        orderType: [
          { label: "全部", value: "" },
          { label: "预订", value: 1 },
          { label: "抢票", value: 2 },
        ],
        refundStatus: [
          { label: "全部", value: "" },
          { label: "申请退款", value: 1 },
          { label: "退款中", value: 2 },
          { label: "退款成功", value: 3 },
          { label: "退款失败", value: 4 },
          { label: "取消退款", value: 5 },
        ],
      },
      pager: {
        pageSizes: [10, 20, 30, 40, 50],
        total: 0,
      },
      list: [],
    };
  },
  methods: {
    req_list(params, type) {
      this.loading = true;
      const _this = this;
      Q.when()
        .then(function() {
          return trains_order_buyerRefundList(params);
        })
        .then(function(res) {
          _this.list = res.pageResult.pageData;
          _this.list.forEach(function(item) {
            item.bookingDate = moment(item.bookingDate).format(
              "YYYY-MM-DD HH:mm"
            );
            item.refundDate = item.refundDate
              ? moment(item.refundDate).format("YYYY-MM-DD HH:mm")
              : "--";
          });
          _this.pager.total = res.pageResult.totalCount;
          if (type) document.documentElement.scrollTop = 0;
          _this.loading = false;
        });
    },
    req_change_remark(params) {
      const _this = this;
      Q.when()
        .then(function() {
          return trains_order_updateRefundRemark(params);
        })
        .then(function(res) {
          _this.handleClose();
          _this.req_list(_this.form);
        });
    },
    search() {
      this.form.currentPage = 1;
      this.req_list(this.form);
    },
    reset() {
      const pageSize = this.form.pageSize;
      this.departDate = null;
      this.reserveDate = null;

      this.fromCity = {};
      this.toCity = {};
      this.form = {
        currentPage: 1,
        pageSize,
        orderNo: "",
        passengerName: "",
        trainNo: "",
        orderType: "",
        refundStatus: "",
        fromStationSn: "",
        toStationSn: "",
        beginStartDate: "",
        endStartDate: "",
        beginBookingDate: "",
        endBookingDate: "",
      };
      this.req_list(this.form);
    },
    change_size(pageSize) {
      this.form.pageSize = pageSize;
      this.form.currentPage = 1;
      this.req_list(this.form, "pageSize");
    },
    change_current(currentPage) {
      this.form.currentPage = currentPage;
      this.req_list(this.form, "currentPage");
    },
    popup_remark(row) {
      const form = this.remarkForm;
      form.refundNo = row.refundNo;
      form.remark = row.remark;
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
      const form = this.remarkForm;
      form.refundNo = "";
      form.remark = "";
      this.$refs.remark.clearValidate();
    },
    commit_remark() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.req_change_remark(this.remarkForm);
        } else {
          return false;
        }
      });
    },
  },
  watch: {
    departDate(val) {
      if (val) {
        this.form.beginStartDate = val[0] + " 00:00:00";
        this.form.endStartDate = val[1] + " 23:59:59";
      } else {
        this.form.beginStartDate = "";
        this.form.endStartDate = "";
      }
    },
    reserveDate(val) {
      if (val) {
        this.form.beginBookingDate = val[0] + " 00:00:00";
        this.form.endBookingDate = val[1] + " 23:59:59";
      } else {
        this.form.beginBookingDate = "";
        this.form.endBookingDate = "";
      }
    },
    fromCity(val) {
      console.log(val);
      if (val) {
        this.form.fromStationSn = val.sn;
      } else {
        this.form.fromStationSn = "";
      }
    },
    toCity(val) {
      if (val) {
        this.form.toStationSn = val.sn;
      } else {
        this.form.toStationSn = "";
      }
    },
  },
  filters: {
    journey(row) {
      const gmtStart = moment(row.gmtStart);
      const gmtArrive = moment(row.gmtArrive);
      if (gmtStart.diff(gmtArrive, "days") === 0) {
        return (
          gmtStart.format("YYYY-MM-DD HH:mm") +
          " - " +
          gmtArrive.format("HH:mm")
        );
      } else {
        return (
          gmtStart.format("YYYY-MM-DD HH:mm") +
          " -" +
          gmtArrive.format("YYYY-MM-DD HH:mm")
        );
      }
    },
    format_status(val) {
      switch (val) {
        case 1:
          return "申请退款";
          break;
        case 2:
          return "退款中";
          break;
        case 3:
          return "退款成功";
          break;
        case 4:
          return "退款失败";
          break;
        case 5:
          return "取消退款";
          break;
      }
    },
  },
  mounted() {},
  activated() {
    this.req_list(this.form);
  },
};
